import { Component, OnInit } from '@angular/core';
import { IFormInputValidationState } from 'src/app/models/IFormInputValidationState';
import { TwoFactorService } from 'src/app/services/two-factor.service';
import { IAlertSetup } from '../../../models/IAlertSetup';
import { AlertSetup } from '../../../models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-reset-authenticator-app',
  templateUrl: './reset-authenticator-app.component.html',
  styleUrls: ['./reset-authenticator-app.component.css'],
})
export class ResetAuthenticatorAppComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();
  validationState: IFormInputValidationState = {} as IFormInputValidationState;

  constructor(
    private _2FA: TwoFactorService,
    private _authService: AuthenticationService
  ) {}

  ngOnInit() {}

  reset(): void {
    this._2FA.ResetAuthenticatorKey().subscribe(
      (response) => {
        this.validationState.isValid = true;
        this.validationState.message = response.message;
        this._authService.RefresStoredCurrentUserData();
        this.alert = new AlertSetup({
          show: true,
          message: response.message,
          alertType: AlertTypes.success,
        });
      },
      (error) => {
        this.validationState.isValid = false;
        this.validationState.message = error.error.message;

        this.alert = new AlertSetup({
          show: true,
          message: error.error.message,
          alertType: AlertTypes.danger,
        });
      }
    );
  }
}
