import { IUser } from './IUser';
import { ICustomer } from './ICustomer';

export class User implements IUser {
  id?: string;
  displayName: string;
  userName: string;
  email: string;
  twoFactorEnabled: boolean;
  customer: ICustomer;

  constructor(user: User = null) {
    if (user !== null) {
      Object.assign(this, user);
    }
  }
}
