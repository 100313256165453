import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _toastr: ToastrService) {}

  Success(message: string): number {
    return this._toastr.success(message).toastId;
  }

  Error(message: string): number {
    return this._toastr.error(message).toastId;
  }

  Info(message: string): number {
    return this._toastr.info(message).toastId;
  }

  Warning(message: string): number {
    return this._toastr.warning(message).toastId;
  }

  Remove(toastId: number): void {
    this._toastr.remove(toastId);
  }

  Clear(): void {
    this._toastr.clear();
  }
}
