<div class="row">
  <div class="col-12">
    <app-authenticator-app></app-authenticator-app>
  </div>
</div>
<div class="row mt-5">
  <div class="col-6">
    <H3 class="h3">Eszközök</H3>
    <app-user-agent-list-view
      [list]="userAgentList"
    ></app-user-agent-list-view>
  </div>
  <div class="col-6"></div>
</div>
