import { IExcelRow } from './Interfaces/IExcelRow';

export class ExcelRow implements IExcelRow {
  rowNumber: number;
  date: Date;
  email: string;
  phoneNumber: string;
  revenue: number;
  isValid: boolean;
  errors: string[];

  constructor(excelRow: IExcelRow = null) {
    if (excelRow !== null) {
      this.rowNumber = excelRow.rowNumber;
      this.date = new Date(excelRow.date);
      this.email = excelRow.email;
      this.phoneNumber = excelRow.phoneNumber;
      this.revenue = excelRow.revenue;
      this.errors = excelRow.errors;
    }
  }
}
