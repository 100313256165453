<h2>Authenticator app</h2>
<p></p>
<app-alert [alert]="alert"></app-alert>
<p></p>
<div class="btn-group" role="group" aria-label="Basic example">
  <a
    type="button"
    class="btn btn-primary"
    ng-li
    routerLink="/setup-authenticator-app"
  >
    Setup authenticator app
  </a>
  <a
    type="button"
    class="btn btn-warning"
    routerLink="/reset-authenticator-app"
  >
    Reset authenticator app
  </a>
</div>
