import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-tag-manager-code-block',
  templateUrl: './tag-manager-code-block.component.html',
  styleUrls: ['./tag-manager-code-block.component.css'],
})
export class TagManagerCodeBlockComponent implements OnInit, OnChanges {
  tagManagerCodeTemplate: string = `!function(e,t){document.addEventListener("DOMContentLoaded",function(){document.querySelectorAll(".impaqtor-form")
  .length>0&&((t=e.createElement("script")).type="text/javascript",t.onload=function(){},t
  .src="http://metrics.test:8000/service?token="+ServiceToken,e.getElementsByTagName("head")[0]
  .appendChild(t))})}(document,ServiceToken="{{accessToken}}");`;

  tagManagerCode: string;

  @Input() accessToken: string = '';
  show: boolean = false;

  constructor(
    private _toast: ToastService,
    private _clipboard: ClipboardService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.show = true;
    if (changes.accessToken.currentValue !== undefined) {
      this.tagManagerCode = this.tagManagerCodeTemplate.replace(
        '{{accessToken}}',
        changes.accessToken.currentValue
      );
    } else {
      this.tagManagerCode = this.tagManagerCodeTemplate;
    }
  }

  copyToClipboard(): void {
    this._clipboard.copyFromContent(this.tagManagerCode);
    this._toast.Success('Kód sikeresen a vágólapra másolva!');
  }

  ngOnInit(): void {}
}
