import { IStorageState } from './IStorageState';
import { IUser } from 'src/app/models/IUser';
import { ConfigService } from 'src/app/Services/config.service';

export class LocalStorageState implements IStorageState {
  private _config: ConfigService = new ConfigService();

  constructor() {}

  ReadCurrentUser(): IUser {
    return JSON.parse(
      localStorage.getItem(this._config.currentUserStorageName)
    );
  }
  ReadAuthToken(): string {
    return localStorage.getItem(this._config.authTokenStorageName);
  }

  StoreAuthToken(token: string): void {
    localStorage.setItem(this._config.authTokenStorageName, token);
  }
  StoreCurrentUser(user: IUser): void {
    localStorage.setItem(
      this._config.currentUserStorageName,
      JSON.stringify(user)
    );
  }
  RemoveAuthToken(): void {
    localStorage.removeItem(this._config.authTokenStorageName);
  }
  RemoveCurrentUser(): void {
    localStorage.removeItem(this._config.currentUserStorageName);
  }
}
