<select
  class="custom-select custom-select-lg mb-3"
  (change)="selectSite()"
  [(ngModel)]="current"
>
  <option [ngValue]="null">Válassz weboldalt</option>
  <option [ngValue]="site" *ngFor="let site of sites">
    {{ site.name }} -- {{ site.host }}
  </option>
</select>
