<app-navbar></app-navbar>

<div class="imp-middle-container-fluid">
  <div class="row">
    <div class="col-3">
      <!-- Side menu -->
      <app-side-menu></app-side-menu>
    </div>
    <div class="col-9">
      <div class="container-fluid homepages">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<footer class="footer">
  <div class="row">
    <div class="col-6"></div>
    <div class="col-5">
      <ul class="imp-footer-menu">
        <li><a href="#">Segítség</a></li>
        <li><a href="">Impresszum</a></li>
        <li><a href="">Általános szerződési feltételek</a></li>
        <li><a href="">Adatkezelési nyilatkozat</a></li>
      </ul>
      <span class="imp-copyright"
        >Impaqtor BI - Lead generation doing it a different way&copy Minden jog
        fenntartva 2020</span
      >
    </div>
    <div class="col-1">
      <div class="imp-footer-logo"></div>
    </div>
  </div>
</footer>
