import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/Services/config.service';
import { Observable } from 'rxjs';
import { IReport } from '../models/Interfaces/IReport';
import { map } from 'rxjs/operators';
import { IReportResponse } from '../models/Interfaces/IReportResponse';
import { Report } from '../models/Report';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  GetReport(): Observable<IReport[]> {
    return this._http.get(`${this._config.apiUrl}/api/Reports`).pipe(
      map((response: IReportResponse[]) => {
        let result: IReport[] = [];
        for (var reportResponse of response) {
          result.push(new Report(reportResponse));
        }

        return result;
      })
    );
  }
}
