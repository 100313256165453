import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExcelUploadLog } from '../models/ExcelUploadLog';
import { ExcelUploadService } from '../services/excel-upload.service';


@Injectable()
export class ExcelUploadLogResolver implements Resolve<ExcelUploadLog[]> {
    constructor(private _excel: ExcelUploadService) {}
  
    resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<ExcelUploadLog[]> {
      return this._excel.GetLogs().pipe(
        catchError((error) => {
          return of(null);
        })
      );
    }
  }