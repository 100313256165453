import { IReport } from './Interfaces/IReport';
import { IReportRow } from './Interfaces/IReportRow';
import { IReportResponse } from './Interfaces/IReportResponse';

export class Report implements IReport {
  date: Date;
  rows: IReportRow[] = [];

  constructor(report: IReportResponse) {
    this.date = new Date(report.date);
    for (var row of report.rows) {
      this.rows.push({
        source_medium: row.sourceMedium,
        online_conversions: row.numberOfTouchesSum,
        generated_leads: row.generatedLeads,
        offline_converisons: row.transaction,
        revenue_sum: row.revenueSum,
        marketing_cost: 0,
        financial_balance: 0,
      });
    }
  }
}
