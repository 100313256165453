import { Component, OnInit, Input } from '@angular/core';
import { IAlertSetup } from '../../models/IAlertSetup';
import { AlertSetup } from '../../models/AlertSetup';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  @Input() alert: IAlertSetup = new AlertSetup();

  constructor() {}

  ngOnInit() {}
}
