import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-helper',
  templateUrl: './question-helper.component.html',
  styleUrls: ['./question-helper.component.css'],
})
export class QuestionHelperComponent implements OnInit {
  @Input() helpText: string;
  constructor() {}

  ngOnInit(): void {}
}
