import { IAlertSetup } from './IAlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';

export class AlertSetup implements IAlertSetup {
  show: boolean = false;
  message: string = 'Dummy message';
  alertType: AlertTypes = AlertTypes.primary;

  constructor(alert: IAlertSetup = null) {
    if (alert !== null) {
      Object.assign(this, alert);
    }
  }

  Show(show: boolean): void {
    this.show = show;
  }

  SetMessage(message: string): void {
    this.message = message;
  }

  SetPrimary(): void {
    this.alertType = AlertTypes.primary;
  }

  SetSuccess(): void {
    this.alertType = AlertTypes.success;
  }

  SetDanger(): void {
    this.alertType = AlertTypes.danger;
  }
}
