import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { IUser } from '../../models/IUser';
import { IAlertSetup } from '../../models/IAlertSetup';
import { AlertSetup } from '../../models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';

@Component({
  selector: 'app-two-factor-setup',
  templateUrl: './two-factor-setup.component.html',
  styleUrls: ['./two-factor-setup.component.css'],
})
export class TwoFactorSetupComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();

  constructor(private _auth: AuthenticationService) {}

  ngOnInit(): void {
    this._auth.CurrentUserSubject.subscribe(
      (response: IUser) => {
        if (response.twoFactorEnabled) {
          this.alert = new AlertSetup({
            show: true,
            message: `Két faktoros azonosítás bekapcsolva`,
            alertType: AlertTypes.success,
          });
        } else {
          this.alert = new AlertSetup({
            show: true,
            message: `Két faktoros azonosítás nincs bekapcsolva`,
            alertType: AlertTypes.info,
          });
        }
      },
      (error) => console.error(error)
    );
  }
}
