import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertSetup } from '../../models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  signUpForm: FormGroup;
  signupError: boolean = false;
  AlertSetup: IAlertSetup = new AlertSetup();

  constructor(private _auth: AuthenticationService) {}

  ngOnInit() {
    this.signUpFromHandler();
  }

  signup() {
    this._auth.SignUp(this.signUpForm.value).subscribe(
      (response) => {
        console.log(response);
        const message: string = `Sikeres regisztráció, 
        kérem nézze meg a megadott email fiókot, 
        és kattintson a megadott linkre a regisztráció befejezéséhez!`;

        this.AlertSetup = new AlertSetup({
          show: true,
          message: message,
          alertType: AlertTypes.success,
        });
      },
      (error: HttpErrorResponse) => {
        console.error(error);

        const message: string = `A regisztráció nem sikerült,
        kérem vegye fel velünk a kapcsolatot info@impaqtor.bi.hu!\n
        Hibaüzenet: ${error.error.message}`;

        this.AlertSetup = new AlertSetup({
          show: true,
          message: message,
          alertType: AlertTypes.success,
        });
      }
    );
  }

  signUpFromHandler() {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      password_confirm: new FormControl('', [Validators.required]),
    });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('password_confirm').value
      ? null
      : { mismatch: true };
  }
}
