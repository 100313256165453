import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/Services/config.service';
import { Observable } from 'rxjs';
import { ISite } from 'src/app/models/Interfaces/ISite';
import { Site } from '../models/Site';
import { map } from 'rxjs/operators';
import { IMetricsForm } from 'src/app/models/Interfaces/IMetricsForm';
import { MetricsForm } from '../models/MetricsForm';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  GetAccessToken(siteResource: string): Observable<{ access_token: string }> {
    return this._http.get<{ access_token: string }>(
      `${this._config.metricsProductApi}/accesstoken?resource=${siteResource}`
    );
  }

  GetForms(siteResource: string): Observable<IMetricsForm[]> {
    return this._http
      .get<IMetricsForm[]>(
        `${this._config.metricsProductApi}/forms?resource=${siteResource}`
      )
      .pipe(
        map((response: IMetricsForm[]) => {
          let result = [];
          for (let form of response) {
            result.push(new MetricsForm(form));
          }

          return result;
        })
      );
  }

  GetCustomerSites(customerResource: string): Observable<Site[]> {
    return this._http
      .get<ISite[]>(
        `${this._config.connectorProdutcApi}/api/sites/customerResource/${customerResource}`
      )
      .pipe(
        map((response: ISite[]) => {
          let result = [];
          for (let site of response) {
            result.push(new Site(site));
          }

          return result;
        })
      );
  }
}
