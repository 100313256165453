import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/Services/config.service';

@Component({
  selector: 'app-excel-sample-download-link',
  templateUrl: './excel-sample-download-link.component.html',
  styleUrls: ['./excel-sample-download-link.component.css'],
})
export class ExcelSampleDownloadLinkComponent implements OnInit {
  @Input() anchorText: string = 'kattints ide!';

  constructor(public config: ConfigService) {}

  ngOnInit(): void {}
}
