import { IUserAgent } from './Interfaces/IUserAgent';
export class UserAgent implements IUserAgent {
  agentFamily: string;
  osFamily: string;
  deviceFamily: string;
  createdAt: Date;

  constructor(userAgent: IUserAgent = null) {
    if (userAgent !== null) {
      this.agentFamily = userAgent.agentFamily;
      this.osFamily = userAgent.osFamily;
      this.deviceFamily = userAgent.deviceFamily;
      this.createdAt = new Date(userAgent.createdAt);
    }
  }
}
