<div class="row">
    <div class="col-12">
      <!-- <app-report-selector-options></app-report-selector-options> -->
      
      <accordion [isAnimated]="true">
        <accordion-group *ngFor="let report of reports; let i = index" [isOpen]="i === 0" class="mb-3">
          <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
            <div class="pull-left float-left"><i class="fas fa-2x fa-chart-line"></i></div>
            <span class="badge badge-secondary float-right pull-right">{{ report.date | date_for_report_list }}</span>
          </button>
          <div class="embed-responsive mb-5">
            <app-report-data-show-table
              [report]="report"
              [index]="i"
            ></app-report-data-show-table>
            
          </div>
        </accordion-group>
      </accordion>
    </div>
</div>
