<div class="imp-login-body">
  <div class="imp-login-wrapper">
    <form
      class="imp-form-signin"
      [formGroup]="newPasswordForm"
      (submit)="send()"
    >
      <h1 class="h1 mb-3">IMPAQTOR BI</h1>
      <!-- <div class="imp-bi-text-logo">
            <span class="imp-bi-logo-business">Business</span>
            <span class="imp-bi-logo-intelligence">Intelligence</span>
          </div> -->
      <h4 class="h4 mb-3 font-weight-normal imp-center-text">
        Kérlek regisztrálj!
      </h4>
      <app-alert [alert]="AlertSetup"></app-alert>

      <div class="form-group">
        <label for="inputPassword" class="imp-control-label">Jelszó</label>
        <input
          type="password"
          class="form-control imp-form-control"
          id="password"
          formControlName="password"
          placeholder="password"
        />
      </div>

      <div class="form-group">
        <label for="inputPasswordConfirm" class="imp-control-label"
          >Jelszó megerősítése</label
        >
        <input
          type="password"
          formControlName="password_confirm"
          class="form-control imp-form-control"
          id="inputPasswordConfirm"
          placeholder="password confirm"
        />
      </div>

      <button class="btn btn-lg btn-primary btn-block mt-5" type="submit">
        Küldés
      </button>
    </form>
    <div class="imp-login-logo-wrapper"></div>
  </div>
</div>
