import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ExcelUploadService } from '../../services/excel-upload.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { IAlertSetup } from '../../models/IAlertSetup';
import { AlertSetup } from '../../models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { IExcelRow } from '../../models/Interfaces/IExcelRow';
import { ActivatedRoute } from '@angular/router';
import { IExcelUploadLog } from '../../models/Interfaces/IExcelUploadLog';
import { DataPayloadSignalRService } from 'src/app/services/SignalR/data-payload-signal-r.service';
import { IPhase } from '../../models/IPhase';
import { IPhaseResult } from '../../models/IPhaseResult';
import { ExcelUploadLog } from 'src/app/models/ExcelUploadLog';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  providers: [{ provide: ProgressbarConfig }],
})
export class UploadComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();
  phase: number = -1;
  showFileInput: boolean = true;
  last: ExcelUploadLog;

  @ViewChild('excelInput', { static: false })
  excelInput: ElementRef<HTMLInputElement>;

  constructor(
    private _excelUpload: ExcelUploadService,
    private _route: ActivatedRoute,
    public _signalR: DataPayloadSignalRService
  ) {}

  invalidUploadRows: IExcelRow[] = [];
  logs: IExcelUploadLog[] = [];

  upload(): void {
    this.refreshLogs();
    this.alert = new AlertSetup();
    this.last = null;
    this.showFileInput = false;
    this._signalR.ListenUploadStatus((data: IPhase | IPhaseResult) => {
      console.log(data);
      if (this._signalR.Phase === 3) {
        let message: string = '';
        let result = data as IPhaseResult;
        for (let date of result.changedReports) {
          message += date + '; ';
        }
        this.alert = new AlertSetup({
          show: true,
          message: `Változás a következő riportokban: ${message}`,
          alertType: AlertTypes.success,
        });
        this.getLast();
        //this.refreshLogs();
      }
    });

    const file = this.excelInput.nativeElement.files[0];
    this._excelUpload.Upload(file).subscribe(
      (response) => {
        this.invalidUploadRows = response;
      },
      (error: HttpErrorResponse) => {
        this.alert = new AlertSetup({
          show: true,
          message: `Feltöltés sikertelen`,
          alertType: AlertTypes.success,
        });
      }
    );
  }

  public refreshLogs(): void {
    this._excelUpload.GetLogs().subscribe((response: IExcelUploadLog[]) => {
      this.logs = response;
    });
  }

  private getLogs(): void {
    this._route.data.subscribe((data: []) => {
      this.logs = data['logs'];
    });
  }

  private getLast(): void {
    this._excelUpload.Last().subscribe(
      (res) => {
        this.last = res;
      },
      (err: HttpErrorResponse) => console.error(err)
    );
  }

  ngOnInit(): void {
    this.getLogs();
    this._signalR.StartConnection();
  }
}
