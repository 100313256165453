import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ILoginWithTwoFACode } from 'src/app/models/auth/ILoginWithTwoFACode';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ILogin } from 'src/app/models/auth/ILogin';
import { AlertSetup } from 'src/app/models/AlertSetup';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { RedirectService } from '../../services/redirect.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/User';

import { ICustomer } from '../../models/ICustomer';
import { CustomerService } from 'src/app/Services/customer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  twoFA: boolean = false;
  twoFAForm: FormGroup;
  AlertSetup: IAlertSetup = new AlertSetup();

  private _loginModel: ILogin;

  constructor(
    private _auth: AuthenticationService,
    private _redirect: RedirectService,
    private _route: ActivatedRoute,
    private _customer: CustomerService
  ) {}

  ngOnInit() {
    this.getMessage();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(8),
      ]),
      stayLoggedIn: new FormControl(false),
    });

    this.twoFAForm = new FormGroup({
      code: new FormControl('', [Validators.required]),
    });
  }

  private getMessage(): void {
    this._route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('message') !== null) {
        this.AlertSetup = new AlertSetup({
          show: true,
          message: queryParams.get('message'),
          alertType: AlertTypes.primary,
        });
      }
    });
  }

  twoFASend(): void {
    var loginModel: ILoginWithTwoFACode = {} as ILoginWithTwoFACode;
    loginModel.email = this._loginModel.email;
    loginModel.password = this._loginModel.password;
    loginModel.code = this.twoFAForm.value.code;
    this._auth.LoginWithTwoFACode(loginModel).subscribe(
      (response) => {
        this.AlertSetup = new AlertSetup({
          show: true,
          message: `Sikeres bejelentkezés`,
          alertType: AlertTypes.success,
        });
        this.getCustomer();
        this._redirect.ToHome();
      },
      (error: HttpErrorResponse) => {
        this.AlertSetup = new AlertSetup({
          show: true,
          message: `Sikertelen bejelentkezés`,
          alertType: AlertTypes.danger,
        });
      }
    );
  }

  private getCustomer(): void {
    this._auth.CurrentUserSubject.subscribe((responseUser: User) => {
      this._customer
        .GetCustomerByUser(responseUser.id)
        .subscribe((responseCustomers: ICustomer[]) => {
          responseUser.customer = responseCustomers[0];
          this._auth.CurrentUserSubject.next(responseUser);
        });
    });
  }

  login(): void {
    this._loginModel = this.loginForm.value;
    this._auth.Login(this._loginModel).subscribe(
      (response) => {
        this.AlertSetup = new AlertSetup({
          show: true,
          message: `Sikeres bejelentkezés`,
          alertType: AlertTypes.success,
        });
        this.getCustomer();
        this._redirect.ToDashboard();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 444) {
          this.twoFA = true;
        } else if (error.status === 455) {
          this.AlertSetup = new AlertSetup({
            show: true,
            message: `Ismeretlen eszköz, kérlek menj el az email 
            fiókodba, és erősítsd meg az eszközöd!`,
            alertType: AlertTypes.info,
          });
        } else {
          this.AlertSetup = new AlertSetup({
            show: true,
            message: `Sikertelen bejelentkezés`,
            alertType: AlertTypes.danger,
          });
        }
      }
    );
  }
}
