<div class="imp-login-body">
  <div class="imp-login-wrapper">
    <form
      *ngIf="!twoFA"
      class="imp-form-signin"
      [formGroup]="loginForm"
      (ngSubmit)="login()"
    >
      <h1 class="h1 mb-3">IMPAQTOR BI</h1>
      <!-- <div class="imp-bi-text-logo">
          <span class="imp-bi-logo-business">Business</span>
          <span class="imp-bi-logo-intelligence">Intelligence</span>
        </div> -->
      <h4 class="h4 mb-3 font-weight-normal imp-center-text">
        Kérlek jelentkezz be!
      </h4>
      <app-alert [alert]="AlertSetup"></app-alert>
      <div class="form-group">
        <label for="inputEmail" class="imp-control-label">Email cím</label>
        <input
          type="email"
          formControlName="email"
          class="form-control imp-form-control"
          id="inputEmail"
          aria-describedby="emailHelp"
          placeholder="Email address"
        />
        <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
      </div>
      <div class="form-group">
        <label for="inputPassword" class="imp-control-label">Jelszó</label>
        <input
          type="password"
          formControlName="password"
          class="form-control imp-form-control"
          id="inputPassword"
          placeholder="password"
        />
      </div>

      <div class="custom-control custom-checkbox mb-3 mt-3">
        <input
          type="checkbox"
          formControlName="stayLoggedIn"
          class="custom-control-input"
          value="remember-me"
          id="stayLogin"
        />
        <label class="custom-control-label imp-control-label" for="stayLogin"
          >Maradjak bejelentkezve!</label
        >
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit">
        Bejelentkezés
      </button>

      <p class="mt-4 mb-2 text-muted imp-center-text">
        <a routerLink="/lost-password" class="imp-center-text imp-login-anchor"
          >Elfelejtett jelszó</a
        >
      </p>
      <p class="mt-4 mb-2 text-muted imp-center-text">
        <a routerLink="/registration" class="imp-center-text imp-login-anchor"
          >Regisztráció</a
        >
      </p>
    </form>

    <form
      *ngIf="twoFA"
      class="imp-form-signin"
      [formGroup]="twoFAForm"
      (ngSubmit)="twoFASend()"
    >
      <h1 class="h1 mb-3">IMPAQTOR BI</h1>
      <!-- <div class="imp-bi-text-logo">
          <span class="imp-bi-logo-business">Business</span>
          <span class="imp-bi-logo-intelligence">Intelligence</span>
        </div> -->
      <h4 class="h4 mb-3 font-weight-normal imp-center-text">
        Két faktoros azonosítás
      </h4>
      <h5 class="h5 mb-3 font-weight-normal imp-center-text">
        Kérlek add meg a Google/Microsoft Authenticator kódját!
      </h5>
      <app-alert [alert]="AlertSetup"></app-alert>
      <div class="form-group">
        <label for="inputCode" class="imp-control-label"
          >Authenticator Code</label
        >
        <input
          type="text"
          formControlName="code"
          class="form-control imp-form-control"
          id="inputCode"
          aria-describedby="emailHelp"
          placeholder="Authenticator Code"
        />
        <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit">
        Bejelentkezés
      </button>
    </form>
    <div class="imp-login-logo-wrapper"></div>
  </div>
</div>
