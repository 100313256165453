import { Component, Input, OnInit } from '@angular/core';
import { ISite } from 'src/app/models/Interfaces/ISite';

@Component({
  selector: 'app-site-list-view',
  templateUrl: './site-list-view.component.html',
  styleUrls: ['./site-list-view.component.css']
})
export class SiteListViewComponent implements OnInit {
  @Input() list: ISite[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
