import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent implements OnInit {
  message: string = '';

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getMessage();
  }

  private getMessage(): void {
    this._route.queryParamMap.subscribe((queryParams) => {
      this.message = queryParams.get('message');
    });
  }
}
