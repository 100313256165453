<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <button
          class="btn btn-sm btn-outline-secondary"
          type="button"
          (click)="printTable(CreateReportTableId(report.date, index))"
        >
          <i class="fas fa-print"></i>
          &nbsp;Nyomtatás
        </button>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input
        class="form-control mr-sm-2"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
        Search
      </button>
    </form>
  </div>
</nav>
<table [id]="CreateReportTableId(report.date, index)" class="table table-hover">
  <thead>
    <tr>
      <th scope="col">Forrás/médium</th>
      <th scope="col">Online konverziók</th>
      <th scope="col">Keletkezett leadek</th>
      <th scope="col">Offline konverziók</th>
      <th scope="col">
        Bevétel
        <app-question-helper
          [helpText]="
            'Offline eladásból származó bevétel csatornához köthető része'
          "
        ></app-question-helper>
      </th>
      <th scope="col">Marketing költség</th>
      <th scope="col">Pénzügyi egyenleg</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of report.rows">
      <th scope="row">{{ row.source_medium }}</th>
      <td>{{ row.online_conversions }}</td>
      <td>{{ row.generated_leads }}</td>
      <td>{{ row.offline_converisons }}</td>
      <td>{{ row.revenue_sum }} HUF</td>
      <td>
        <app-edited-cell
          [cost]="row.marketing_cost"
          [fieldName]="'Marketing költség'"
          (setMarketingCost)="setMarketingCost($event, row)"
          (reset)="resetMarketingCost(row)"
        ></app-edited-cell>
      </td>

      <!-- <td>{{ row.financial_balance }} HUF</td> -->
      <td [innerText]="row.financial_balance + ' HUF'"></td>
    </tr>
  </tbody>
</table>
