import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { RedirectService } from '../services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class LogInGuard implements CanActivate {
  constructor(
    private _auth: AuthenticationService,
    private _redirect: RedirectService
  ) {}

  canActivate(): boolean {
    if (!this._auth.LocalLoginCheck()) {
      return true;
    }

    this._auth.LocalLogin();
    this._redirect.ToDashboard();
    return true;
  }
}
