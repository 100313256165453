import { Component, Input, OnInit } from '@angular/core';
import { IExcelUploadLog } from '../../models/Interfaces/IExcelUploadLog';

@Component({
  selector: 'app-excel-upload-log-list-indicator',
  templateUrl: './excel-upload-log-list-indicator.component.html',
  styleUrls: ['./excel-upload-log-list-indicator.component.css']
})
export class ExcelUploadLogListIndicatorComponent implements OnInit {
  @Input() list: IExcelUploadLog[] = []
  constructor() { }

  ngOnInit(): void {
  }

}
