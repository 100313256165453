import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WebformsComponent } from './pages/webforms/webforms.component';
import { UploadComponent } from './pages/upload/upload.component';
import { ReportComponent } from './pages/report/report.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { LostPasswordComponent } from './pages/lost-password/lost-password.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AuthGuard } from './guards/auth.guard';
import { TwoFactorSetupComponent } from './pages/two-factor-setup/two-factor-setup.component';
import { SetupAuthenticatorAppComponent } from './pages/two-factor-setup/setup-authenticator-app/setup-authenticator-app.component';
import { ResetAuthenticatorAppComponent } from './pages/two-factor-setup/reset-authenticator-app/reset-authenticator-app.component';
import { LogInGuard } from './guards/log-in.guard';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { CustomerDataComponent } from './pages/customer-data/customer-data.component';
import { SitesResolver } from './resolvers/sites.resolver';
import { UserAgentResolver } from './resolvers/userAgents.resolver';
import { ExcelUploadLogResolver } from './resolvers/excelUploadLog.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'webforms',
        component: WebformsComponent,
        resolve: { sites: SitesResolver },
      },
      { path: 'upload', component: UploadComponent, resolve: {logs: ExcelUploadLogResolver} },
      { path: 'report', component: ReportComponent },
      {
        path: 'user/profile',
        component: UserProfileComponent,
        resolve: {userAgents: UserAgentResolver}
      },
      { path: 'two-factor-setup', component: TwoFactorSetupComponent },
      {
        path: 'setup-authenticator-app',
        component: SetupAuthenticatorAppComponent,
      },
      {
        path: 'reset-authenticator-app',
        component: ResetAuthenticatorAppComponent,
      },
      {
        path: 'customer-data',
        component: CustomerDataComponent,
        resolve: { sites: SitesResolver },
      },
    ],
  },
  { path: 'login', component: LoginComponent, canActivate: [LogInGuard] },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [LogInGuard],
  },
  {
    path: 'lost-password',
    component: LostPasswordComponent,
    canActivate: [LogInGuard],
  },
  { path: 'new-password', component: NewPasswordComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
