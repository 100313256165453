<div class="card code-holder" *ngIf="show">
  <div class="card-header">
    {{ name }}
    <button
      type="button"
      class="btn btn-dark float-right"
      (click)="copyToClipboard()"
    >
      Copy to clipboard &nbsp;&nbsp;<i class="far fa-copy"></i>
    </button>
  </div>
  <div class="card-body code-holder">
    <p class="card-text">{{ embeddedCode }}</p>
  </div>
</div>
