import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { IUser } from '../models/IUser';
import { UserCollection } from '../models/collections';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  GetUserById(userId: string): Observable<UserCollection> {
    return this._http
      .get<UserCollection>(`${this._config.apiUrl}/Users?Id=${userId}`)
      .pipe(
        map((response: UserCollection) => {
          let users: UserCollection = [];

          for (let item of response) {
            users.push(new User(item as any));
          }

          return users;
        })
      );
  }

  GetUserByEmail(email: string): Observable<UserCollection> {
    return this._http
      .get<UserCollection>(`${this._config.apiUrl}/Users?Email=${email}`)
      .pipe(
        map((response: UserCollection) => {
          let users: UserCollection = [];

          for (let item in response) {
            users.push(new User(item as any));
          }

          return users;
        })
      );
  }
}
