import { ISite } from './Interfaces/ISite';

export class Site implements ISite {
  host: string;
  name: string;
  resource: string;

  constructor(site: ISite = null) {
    if (site !== null) {
      this.host = site.host;
      this.name = site.name;
      this.resource = site.resource;
    }
  }
}
