import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { IUser } from '../../../models/IUser';
import { User } from 'src/app/models/User';
import { RedirectService } from '../../../services/redirect.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  CurrentUser: IUser;

  constructor(
    private _auth: AuthenticationService,
    private _redirect: RedirectService
  ) {}

  ngOnInit(): void {
    this._setCurrentUser();
  }

  logout(): void {
    this._auth.Logout();
    this._redirect.ToLogin();
  }

  private _setCurrentUser(): void {
    this.CurrentUser = new User(this._auth.currentUserValue);
  }
}
