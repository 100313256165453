import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ITwoFactorAuth } from '../models/auth/ITwoFactorAuth';
import { IAuthenticatorCode } from '../models/auth/IAuthenticatorCode';
import { ConfigService } from 'src/app/Services/config.service';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorService {
  constructor(private _config: ConfigService, private _http: HttpClient) {}

  GetTwoFactorAuthData(): Observable<ITwoFactorAuth> {
    return this._http.get<ITwoFactorAuth>(
      this._config.apiUrl + '/TwoFactorAuth/sharedKeyAndQrcode'
    );
  }

  VerifycationCode(model: IAuthenticatorCode): Observable<any> {
    return this._http.post(
      this._config.apiUrl + '/TwoFactorAuth/VerifycationCode',
      model
    );
  }

  ResetAuthenticatorKey(): Observable<any> {
    return this._http.post(
      this._config.apiUrl + '/TwoFactorAuth/ResetAuthenticatorKey',
      {}
    );
  }
}
