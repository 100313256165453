import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { ConfigService } from 'src/app/Services/config.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { UploadComponent } from '../../pages/upload/upload.component';
import { IPhaseResult } from 'src/app/models/IPhaseResult';
import { IPhase } from 'src/app/models/IPhase';

@Injectable({
  providedIn: 'root',
})
export class DataPayloadSignalRService {
  public HubConnection: signalR.HubConnection;
  public Phase: number = -1;
  public RunStatus: boolean = false;

  constructor(
    private _config: ConfigService,
    private _auth: AuthenticationService
  ) {}

  public StartConnection = () => {
    this.HubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this._config.apiUrl}/hub/datapayload`, {
        accessTokenFactory: () => this._auth.currentAuthToken,
      })
      .build();

    this.HubConnection.start()
      .then(() => {
        console.log('Connection start');
      })
      .catch((err) => console.log('Error while starting connection: ' + err));
  };

  public ListenUploadStatus = (action: Function) => {
    this.HubConnection.on('uploadstatus', (data: IPhase | IPhaseResult) => {
      this.RunStatus = true;
      this.Phase = data.level;
      if (this.Phase === 3) {
        this.RunStatus = false;
      }
      action(data);
    });
  };
}
