  <accordion [isAnimated]="true">
    <accordion-group *ngFor="let log of list">
      <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
        <div class="pull-left float-left">
            <b>Idő:</b> {{ log.createdAt | timeViewForFileUploadLog}} |
            <b>Dátum:</b> {{ log.createdAt | date }} |
            <b>Feltöltötte:</b> {{ log.uploader.email }}
        </div>
        <span class="badge badge-secondary float-right pull-right">
            Tranzakciók: {{ log.createdTransacitonsCount }}
        </span>
      </button>
      <ul class="list-group">
        <li class="list-group-item">Feltöltő neve: {{ log.customer.name }}</li>
        <li class="list-group-item">Sikeresen feltöltött tranzakciók: {{ log.createdTransacitonsCount }}</li>
        <li class="list-group-item">Sorok száma: {{ log.sourceRowCount }}</li>
        <li class="list-group-item">Fájlnév: {{ log.fileName }}</li>
        <li class="list-group-item">Fájlméret: {{ log.fileSize }}</li>
        <li class="list-group-item">Fájlméret: {{ log.fileSize }}</li>
        <li class="list-group-item">Fájltípus: {{ log.fileType }}</li>        
        <li class="list-group-item">
            Hibák:
            <ul class="list-group">
                <li class="list-group-item list-group-item-danger" *ngFor="let error of log.errors">
                    {{error.message}}
                </li>
              </ul>
        </li>
      </ul>
    </accordion-group>
  </accordion>
