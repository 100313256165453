<div class="imp-login-body">
  <div class="imp-login-wrapper">
    <form
      class="imp-form-signin"
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="send()"
    >
      <h1 class="h1 mb-3">IMPAQTOR BI</h1>
      <!-- <div class="imp-bi-text-logo">
          <span class="imp-bi-logo-business">Business</span>
          <span class="imp-bi-logo-intelligence">Intelligence</span>
        </div> -->
      <h4 class="h4 mb-3 font-weight-normal imp-center-text">
        Kérlek add meg az email címet amivel regisztráltál!
      </h4>
      <app-alert [alert]="alert"></app-alert>
      <div class="form-group mt-4 mb-4">
        <label for="inputEmail" class="imp-control-label">Email cím</label>
        <input
          type="email"
          formControlName="email"
          class="form-control imp-form-control"
          id="inputEmail"
          aria-describedby="emailHelp"
          placeholder="Email address"
        />
        <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
      </div>

      <button class="btn btn-lg btn-primary btn-block mt-4" type="submit">
        Elfelejtettem a jelszavam
      </button>
    </form>
    <div class="imp-login-logo-wrapper"></div>
  </div>
</div>
