import { ICustomer } from './ICustomer';

export class Customer implements ICustomer {
  resource: string;
  name: string;
  machineId: string;

  constructor(customer: ICustomer = null) {
    if (customer !== null) {
      Object.assign(this, customer);
    }
  }
}
