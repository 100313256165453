<div *ngIf="show">
    <button
    type="button"
    class="btn btn-primary btn-block"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseBasic"
  >
    Tekintse meg a feltöltött excel invalid sorait!
  </button>
  <hr />
  <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
    <div class="well well-lg card card-block card-header">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Sorszám</th>
            <th scope="col">Hibák</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows">
            <th scope="row">{{ row.rowNumber }}</th>
            <td>{{ row.errors }}</td>
          </tr>        
        </tbody>
      </table>
    </div>
  </div>
  
</div>
