import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISite } from 'src/app/models/Interfaces/ISite';

@Component({
  selector: 'app-select-site',
  templateUrl: './select-site.component.html',
  styleUrls: ['./select-site.component.css'],
})
export class SelectSiteComponent implements OnInit {
  @Input() sites: ISite[] = [];
  @Output() select = new EventEmitter<ISite>();
  current: ISite = null;
  constructor() {}

  ngOnInit(): void {}

  selectSite(): void {
    this.select.emit(this.current);
  }
}
