import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { UserAgentService } from 'src/app/services/user-agent.service';
import { IUserAgent } from 'src/app/models/Interfaces/IUserAgent';


@Injectable()
export class UserAgentResolver implements Resolve<IUserAgent[]> {
  constructor(private _userAgent: UserAgentService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IUserAgent[]> {
    return this._userAgent.GetUserAgents().pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}
