import { Component, OnInit } from '@angular/core';
import { ISite } from 'src/app/models/Interfaces/ISite';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.css']
})
export class CustomerDataComponent implements OnInit {
  sites: ISite[] = [];

  constructor(private _route: ActivatedRoute) { }

  private getSites(): void {
    this._route.data.subscribe((data) => {
      this.sites = data['sites'];
      console.log(this.sites);
    });
  }

  ngOnInit(): void {
    this.getSites();
  }

}
