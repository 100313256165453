import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IUser } from '../../models/IUser';

@Component({
  selector: 'app-nav-login-indicator',
  templateUrl: './nav-login-indicator.component.html',
  styleUrls: ['./nav-login-indicator.component.css'],
})
export class NavLoginIndicatorComponent implements OnInit, OnChanges {
  @Input() user: IUser;
  show: boolean = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.show = true;
  }

  ngOnInit(): void {}
}
