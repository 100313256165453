import { Component, OnInit } from '@angular/core';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { AlertSetup } from 'src/app/models/AlertSetup';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { IUser } from 'src/app/models/IUser';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-authenticator-app',
  templateUrl: './authenticator-app.component.html',
  styleUrls: ['./authenticator-app.component.css'],
})
export class AuthenticatorAppComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();

  constructor(private _auth: AuthenticationService) {}

  ngOnInit(): void {
    this._auth.CurrentUserSubject.subscribe(
      (response: IUser) => {
        if (response.twoFactorEnabled) {
          this.alert = new AlertSetup({
            show: true,
            message: `Két faktoros azonosítás bekapcsolva`,
            alertType: AlertTypes.success,
          });
        } else {
          this.alert = new AlertSetup({
            show: true,
            message: `Két faktoros azonosítás nincs bekapcsolva`,
            alertType: AlertTypes.info,
          });
        }
      },
      (error) => console.error(error)
    );
  }
}
