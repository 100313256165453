import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IUserAgent } from '../models/Interfaces/IUserAgent';
import { UserAgent } from '../models/UserAgent';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/Services/config.service';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  GetUserAgents(): Observable<IUserAgent[]> {
    return this._http
      .get<IUserAgent[]>(`${this._config.apiUrl}/UserAgents`)
      .pipe(
        map((response: IUserAgent[]) => {
          var result: IUserAgent[] = [];
          for (let agent of response) {
            result.push(new UserAgent(agent));
          }

          return result;
        })
      );
  }
}
