
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">                
                <form id="msform">
                    <!-- progressbar -->
                    <ul id="progressbar">
                        <li [class]="{'active': phase >= 0}" id="upload"><strong>File Upload</strong></li>
                        <li [class]="{'active': phase >= 1}" id="validation"><strong>Validation</strong></li>
                        <li [class]="{'active': phase >= 2}" id="calculation"><strong>Calculation</strong></li>
                        <li [class]="{'active': phase >= 3}" id="finish"><strong>Finish</strong></li>
                    </ul>                    
                    <div class="progress">
                        <progressbar 
                            class="progress-bar-striped progress-bar-animated"
                            [class]="{'progress-bar': phase >= 0 && phase <= 2}" 
                            [value]="0"
                            role="progressbar">
                        </progressbar>
                    </div> <br> <!-- fieldsets -->                 
                </form>
            </div>
        </div>
    </div>
</div>

