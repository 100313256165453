import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IExcelRow } from '../../models/Interfaces/IExcelRow';

@Component({
  selector: 'app-excel-invalid-rows',
  templateUrl: './excel-invalid-rows.component.html',
  styleUrls: ['./excel-invalid-rows.component.css'],
})
export class ExcelInvalidRowsComponent implements OnInit, OnChanges {
  isCollapsed: boolean = true;
  show: boolean = false;
  @Input() rows: IExcelRow[] = [];

  constructor() {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.rows.currentValue.length > 0) {
      this.show = true;
    }
  }

  ngOnInit(): void {}
}
