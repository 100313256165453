import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private _router: Router) {}

  ToLogin(message: string = ''): void {
    if (message !== '') {
      const qp: object = { message: message };
      this._router.navigate(['/login'], { queryParams: qp });
      return;
    }

    this._router.navigate(['/login']);
  }
  ToHome(): void {
    this._router.navigate(['/']);
  }
  ToDashboard(): void {
    this._router.navigate(['/dashboard']);
  }
  ToRegistration(): void {
    this._router.navigate(['/registration']);
  }
}
