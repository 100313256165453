import { Component, OnInit } from '@angular/core';
import { AlertSetup } from '../../models/AlertSetup';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css'],
})
export class LostPasswordComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();

  forgotPasswordForm: FormGroup;

  constructor(private _auth: AuthenticationService) {}

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  private successAlert(): void {
    this.alert = new AlertSetup({
      show: true,
      message: `Email sikeresen elküldve`,
      alertType: AlertTypes.success,
    });
  }

  send(): void {
    this._auth.ForgotPassword(this.forgotPasswordForm.value).subscribe(
      (reposnse) => {
        console.log(reposnse);
        this.successAlert();
      },
      (error: HttpErrorResponse) => console.error(error)
    );
  }
}
