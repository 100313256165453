import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-embedded-code',
  templateUrl: './form-embedded-code.component.html',
  styleUrls: ['./form-embedded-code.component.css'],
})
export class FormEmbeddedCodeComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() formId: string;
  show: boolean = false;
  embeddedCode: string;

  constructor(
    private _clipboardService: ClipboardService,
    private _toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.embeddedCode = `<div id="${this.formId}" class="impaqtor-form"></div>`;
    this.show = true;
  }

  ngOnInit(): void {}

  copyToClipboard(): void {
    if (this.show) {
      this._clipboardService.copyFromContent(this.embeddedCode);
      this._toastr.success('Kód sikeresen a vágólapra másolva!');
    }
  }
}
