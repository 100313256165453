<h5>Reset authenticator key</h5>
<div class="alert alert-warning" role="alert">
  <p>
    <span class="glyphicon glyphicon-warning-sign"></span>
    <strong
      >If you reset your authenticator key your authenticator app will not work
      until you reconfigure it.</strong
    >
  </p>
  <p>
    This process disables 2FA until you verify your authenticator app. If you do
    not complete your authenticator app configuration you may lose access to
    your account.
  </p>
</div>
<form id="reset-authenticator-form" method="post" class="form-group">
  <button
    id="reset-authenticator-button"
    class="btn btn-danger"
    type="submit"
    (click)="reset()"
  >
    Reset authenticator key
  </button>
</form>
<app-alert [alert]="alert"></app-alert>
