import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeViewForFileUploadLog'
})
export class TimeViewForFileUploadLogPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): unknown {
    const hour = new Intl.DateTimeFormat('hu', { hour: '2-digit' }).format(value);
    const minute = new Intl.DateTimeFormat('hu', { minute: '2-digit' }).format(value);
    const second = new Intl.DateTimeFormat('hu', { minute: '2-digit' }).format(value);
    return `${hour}:${minute}:${second}`;
  }

}
