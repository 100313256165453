import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/Services/config.service';
import { Observable } from 'rxjs';
import { IExcelRow } from '../models/Interfaces/IExcelRow';
import { map } from 'rxjs/operators';
import { ExcelRow } from '../models/ExcelRow';
import { ExcelUploadLog } from '../models/ExcelUploadLog';
import { IExcelUploadLog } from '../models/Interfaces/IExcelUploadLog';

@Injectable({
  providedIn: 'root',
})
export class ExcelUploadService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  Upload(excelFile: File): Observable<IExcelRow[]> {
    var formData = new FormData();
    formData.append('File', excelFile);
    return this._http
      .post<IExcelRow[]>(`${this._config.apiUrl}/api/Excel`, formData)
      .pipe(
        map((response: any) => {
          let result: IExcelRow[] = [];
          for (let excelRow of response.rows) {
            result.push(new ExcelRow(excelRow));
          }

          return result;
        })
      );
  }

  Last(): Observable<ExcelUploadLog> {
    return this._http
      .get<ExcelUploadLog[]>(`${this._config.apiUrl}/api/Excel/logs/last`)
      .pipe(
        map((response: IExcelUploadLog[]) => {
          let result: IExcelUploadLog;
          for (let log of response) {
            result = new ExcelUploadLog(log);
          }

          return result;
        })
      );
  }

  GetLogs(): Observable<ExcelUploadLog[]> {
    return this._http
      .get<ExcelUploadLog[]>(`${this._config.apiUrl}/api/Excel/logs`)
      .pipe(
        map((response: IExcelUploadLog[]) => {
          let result: IExcelUploadLog[] = [];
          for (let log of response) {
            result.push(new ExcelUploadLog(log));
          }

          return result;
        })
      );
  }
}
