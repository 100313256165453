import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';

export function getProgressbarConfig(): ProgressbarConfig {
  return Object.assign(new ProgressbarConfig(), {
    animate: true,
    striped: true,
    max: 100,
  });
}

@Component({
  selector: 'app-excel-upload-indicator',
  templateUrl: './excel-upload-indicator.component.html',
  styleUrls: ['./excel-upload-indicator.component.css'],
  providers: [{ provide: ProgressbarConfig, useFactory: getProgressbarConfig }]
})
export class ExcelUploadIndicatorComponent implements OnInit, OnChanges {

  @Input() phase: number = -1;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit(): void {
  }

}
