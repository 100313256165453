import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { AlertSetup } from 'src/app/models/AlertSetup';
import { INewPassword } from 'src/app/models/auth/INewPassword';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { RedirectService } from '../../services/redirect.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css'],
})
export class NewPasswordComponent implements OnInit {
  newPasswordForm: FormGroup;
  AlertSetup: IAlertSetup = new AlertSetup();
  showError: boolean = false;
  params: INewPassword = {} as INewPassword;

  constructor(
    private _route: ActivatedRoute,
    private _auth: AuthenticationService,
    private _redirect: RedirectService
  ) {}

  ngOnInit(): void {
    this.newPasswordFormHandler();
    this.getUrlData();
  }

  send() {
    this.params.password = this.newPasswordForm.value.password;
    this.params.password_confirm = this.newPasswordForm.value.password_confirm;
    console.log(this.params);
    this._auth.NewPassword(this.params).subscribe(
      (reponse) => {
        this._redirect.ToLogin('Most már bejelentkezhetsz az új jelszavaddal');
      },
      (error) => console.error(error)
    );
  }

  newPasswordFormHandler() {
    this.newPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      password_confirm: new FormControl('', [Validators.required]),
    });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('password_confirm').value
      ? null
      : { mismatch: true };
  }

  private getUrlData(): void {
    this._route.queryParamMap.subscribe((queryParams) => {
      this.params.email = queryParams.get('email');
      this.params.token = queryParams.get('token');
      this.AlertSetup = new AlertSetup({
        show: true,
        message: queryParams.get('message'),
        alertType: AlertTypes.info,
      });
    });
  }
}
