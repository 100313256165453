import { Component, Input, OnInit } from '@angular/core';
import { IReport } from '../../models/Interfaces/IReport';
import { IReportRow } from '../../models/Interfaces/IReportRow';

@Component({
  selector: 'app-report-data-show-table',
  templateUrl: './report-data-show-table.component.html',
  styleUrls: ['./report-data-show-table.component.css'],
})
export class ReportDataShowTableComponent implements OnInit {
  @Input() report: IReport;
  @Input() index: number;
  edit: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  public CreateReportTableId(date: Date, index: number): string {
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const dateString: string = `${ye}-${mo}`;
    return `${dateString}-report-table-${index}`;
  }

  setMarketingCost(newCost: number, row: IReportRow): void {
    row.marketing_cost = newCost;
    this.calculateFinancialBalance(row);
  }

  private calculateFinancialBalance(row: IReportRow): void {
    row.financial_balance = row.revenue_sum - row.marketing_cost;
  }

  resetMarketingCost(row: IReportRow): void {
    row.marketing_cost = 0;
    row.financial_balance = 0;
  }

  printTable(tableId: string): void {
    let table = document.getElementById(tableId) as HTMLTableElement;
    var html = '<!DOCTYPE HTML>';
    html += '<html lang="en-us">';
    html += `<head>
      <title>Riport nyomtatási nézet ${tableId}</title>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
      <style></style>
      </head>`;
    html += '<body>';
    html += table.outerHTML;
    html += ``;
    html += '</body>';
    let win = window.open();
    win.document.write(html);
    win.document.close();
  }
}
