import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public apiUrl: string = 'https://imp-customers.appspot.com';
  private _localhostServer: string = 'https://localhost:44344';
  public siteName: string = '';
  public authTokenStorageName: string = 'token';
  public currentUserStorageName: string = 'currentUser';
  public connectorProdutcApi: string = 'https://connector.impaqtor.hu';
  public metricsProductApi: string = 'https://metrics.impaqtor.hu';

  constructor() {
    this.setApiUrl();
  }

  private setApiUrl(): void {
    if (this.getHostName() === 'localhost') {
      this.apiUrl = this._localhostServer;
    }
  }

  private getHostName(): string {
    return window.location.hostname;
  }
}
