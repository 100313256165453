import { IMetricsForm } from './Interfaces/IMetricsForm';

export class MetricsForm implements IMetricsForm {
  formId: string;
  name: string;

  constructor(form: IMetricsForm = null) {
    if (form !== null) {
      this.formId = form.formId;
      this.name = form.name;
    }
  }
}
