import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edited-cell',
  templateUrl: './edited-cell.component.html',
  styleUrls: ['./edited-cell.component.css'],
})
export class EditedCellComponent implements OnInit {
  edit: boolean = false;
  toastId: number;
  isInvalid: boolean = false;
  untouched: boolean = true;
  @Input() fieldName: string = '';
  @Input() cost: number = 0;
  @Output() setMarketingCost: EventEmitter<number> = new EventEmitter<number>();
  @Output() reset: EventEmitter<null> = new EventEmitter<null>();

  constructor(private _toast: ToastService) {}

  ngOnInit(): void {}

  setValue(): void {
    this.isInvalid = false;
    const inputCost = document.getElementById(
      'inputMarketingCost'
    ) as HTMLInputElement;
    inputCost.focus();

    if (Number.isNaN(+inputCost.value)) {
      this.isInvalid = true;
      this.toastId = this._toast.Error(
        `A ${this.fieldName} mezőbe csak egész szám adható meg!`
      );
      return;
    }

    if (+inputCost.value !== 0) {
      this.untouched = false;
    }

    this.setMarketingCost.emit(+inputCost.value);
    this.edit = false;
  }

  dropEdit(): void {
    this.edit = false;
    this.isInvalid = false;
  }

  setReset(): void {
    this.untouched = true;
    this.reset.emit(null);
  }
}
