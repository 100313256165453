import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/Services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICustomer } from '../models/ICustomer';
import { Customer } from '../models/Customer';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  GetCustomers(): Observable<Customer[]> {
    return this._http.get<Customer[]>(`${this._config.apiUrl}/Customers`).pipe(
      map((response: ICustomer[]) => {
        let result: ICustomer[] = [];

        for (let customer of response) {
          result.push(new Customer(customer));
        }

        return result;
      })
    );
  }

  GetCustomerByResource(resource: string): Observable<Customer[]> {
    return this._http
      .get<Customer[]>(
        `${this._config.apiUrl}/Customers?CustomerResource=${resource}`
      )
      .pipe(
        map((response: ICustomer[]) => {
          let result: ICustomer[] = [];

          for (let customer of response) {
            result.push(new Customer(customer));
          }

          return result;
        })
      );
  }

  GetCustomerByUser(userId: string): Observable<Customer[]> {
    return this._http
      .get<Customer[]>(`${this._config.apiUrl}/Customers?UserId=${userId}`)
      .pipe(
        map((response: ICustomer[]) => {
          let result: ICustomer[] = [];

          for (let customer of response) {
            result.push(new Customer(customer));
          }

          return result;
        })
      );
  }
}
