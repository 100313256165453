import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserAgent } from '../../models/Interfaces/IUserAgent';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  userAgentList: IUserAgent[] = [];

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getUserAgentsData();
  }

  private getUserAgentsData(): void {
    this._route.data.subscribe((data: []) => {
      this.userAgentList = data["userAgents"];
    });
  }
}
