<accordion [isAnimated]="true" *ngIf="last">
  <accordion-group [panelClass]="'bg-primary'">
    <button
      class="btn btn-link btn-block clearfix text-white"
      accordion-heading
      type="button"
    >
      <div class="pull-left float-left">
        <b>Idő:</b> {{ last.createdAt | timeViewForFileUploadLog }} |
        <b>Dátum:</b> {{ last.createdAt | date }} | <b>Feltöltötte:</b>
        {{ last.uploader.email }}
      </div>
      <span class="badge badge-secondary float-right pull-right">
        Tranzakciók: {{ last.createdTransacitonsCount }}
      </span>
    </button>
    <ul class="list-group">
      <li class="list-group-item">Feltöltő neve: {{ last.customer.name }}</li>
      <li class="list-group-item">
        Sikeresen feltöltött tranzakciók: {{ last.createdTransacitonsCount }}
      </li>
      <li class="list-group-item">Sorok száma: {{ last.sourceRowCount }}</li>
      <li class="list-group-item">Fájlnév: {{ last.fileName }}</li>
      <li class="list-group-item">Fájlméret: {{ last.fileSize }}</li>
      <li class="list-group-item">Fájlméret: {{ last.fileSize }}</li>
      <li class="list-group-item">Fájltípus: {{ last.fileType }}</li>
      <li class="list-group-item">
        Hibák:
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-danger"
            *ngFor="let error of last.errors"
          >
            {{ error.message }}
          </li>
        </ul>
      </li>
    </ul>
  </accordion-group>
</accordion>
