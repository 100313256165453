<!-- <div *ngIf="cost === undefined && !edit">
  <div class="input-group">
    --.-- HUF
    <button
      class="btn btn-light"
      type="button"
      (click)="edit = true"
      popover="Szerkesztés"
      triggers="hover"
    >
      <i class="fas fa-edit" style="color: goldenrod"></i>
    </button>
  </div>
</div> -->
<div *ngIf="!edit && cost !== undefined">
  <div class="input-group">
    {{ cost }} HUF
    <button
      class="btn btn-light"
      type="button"
      (click)="edit = true"
      popover="Szerkesztés"
      triggers="hover"
    >
      <i class="fas fa-edit" style="color: goldenrod"></i>
    </button>
    <button
      *ngIf="!untouched"
      class="btn btn-light"
      type="button"
      (click)="setReset()"
      popover="Reset"
      triggers="hover"
    >
      <i class="fas fa-trash-alt" style="color: red"></i>
    </button>
  </div>
</div>
<div *ngIf="edit">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': isInvalid }"
      placeholder="Marketing cost"
      [value]="cost"
      id="inputMarketingCost"
      aria-describedby="button-addon2"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="setValue()"
        popover="Ok"
        triggers="hover"
      >
        <i class="far fa-check-circle" style="color: green"></i>
      </button>
    </div>
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="dropEdit()"
        popover="Elvetés"
        triggers="hover"
      >
        <i class="fas fa-times" style="color: red"></i>
      </button>
    </div>
  </div>
</div>
