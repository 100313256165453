import { ICustomer } from './ICustomer';
import { IExcelUploadError } from './Interfaces/IExcelUploadError';
import { IExcelUploadLog } from './Interfaces/IExcelUploadLog';
import { IUser } from './IUser';
import { User } from 'src/app/models/User';
import { Customer } from './Customer';

export class ExcelUploadLog implements IExcelUploadLog {
    fileType: string;
    fileName: string;
    fileSize: number;
    sourceRowCount: number;
    createdTransacitonsCount: number;
    uploader: IUser;
    customer: ICustomer;
    errors: IExcelUploadError[];
    createdAt: Date;

    constructor(excelUploadLog: IExcelUploadLog = null) {
        if(excelUploadLog !== null) {
            this.fileType = excelUploadLog.fileType;
            this.fileName = excelUploadLog.fileName;
            this.fileSize = excelUploadLog.fileSize;
            this.sourceRowCount = excelUploadLog.sourceRowCount;
            this.createdTransacitonsCount = excelUploadLog.createdTransacitonsCount;
            this.uploader = new User(excelUploadLog.uploader);
            this.customer = new Customer(excelUploadLog.customer);
            this.errors = excelUploadLog.errors;
            this.createdAt = new Date(excelUploadLog.createdAt);
        }
    }

}