<nav class="navbar navbar-expand-lg navbar-light imp-navbar">
  <a class="navbar-brand" routerLink="/">
    Business
    <span class="imp-brand-span">Intelligence</span>
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <app-nav-login-indicator
            [user]="CurrentUser"
          ></app-nav-login-indicator>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <button class="dropdown-item" routerLink="/user/profile">
            Felhasználói Profil
          </button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" (click)="logout()">
            Kijelentkezés
          </button>
        </div>
      </li>
    </ul>
  </div>
  <div class="imp-navbar-logo"></div>
</nav>
