import { Component, Input, OnInit } from '@angular/core';
import { IUserAgent } from '../../models/Interfaces/IUserAgent';

@Component({
  selector: 'app-user-agent-list-view',
  templateUrl: './user-agent-list-view.component.html',
  styleUrls: ['./user-agent-list-view.component.css']
})
export class UserAgentListViewComponent implements OnInit {
  @Input() list: IUserAgent[] = []
  constructor() { }

  ngOnInit(): void {
  }

}
