<div class="webforms-embedded-codes">
  <div class="container-lg">
    <div class="row">
      <div class="col-12">
        <app-select-site
          [sites]="sites"
          (select)="selectedSite($event)"
        ></app-select-site>
      </div>
    </div>
  </div>
  <div class="container-lg">
    <div class="row">
      <app-tag-manager-code-block
        [accessToken]="accessToken"
      ></app-tag-manager-code-block>
    </div>
    <div class="row mt-5">
      <h4 class="h4">Űrlap beágyazó kódok</h4>
      <h3 class="h3">HEAD beágyazó kód</h3>
      <p>A weboldal html kódjában kell elhelyezni az alábbi forráskódot:</p>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let form of forms">
          <app-form-embedded-code
            [name]="form.name"
            [formId]="form.formId"
          ></app-form-embedded-code>
        </li>
      </ul>
    </div>
  </div>
</div>
