<div class="container-lg">
  <div class="dashboard-info">
    <div class="user-info-area">
      <div class="row">
        <div class="col-1">
          <i class="fas fa-info-circle"></i>
        </div>
        <div class="col-11 dashboard-text">
          <p>Ezen a felületen tudod</p>
          <ol>
            <li>
              Webes űrlapjaid beágyazó kódjait megtekinteni - Be kell ágyaznod
              az ?rlapokat a weboldaladon, hogy elinduljon az online mérés.
            </li>
            <li>
              Feltölteni offline eladásaidat - Fel kell töltened az offline
              eladásaidat, hogy össze lehessen kapcsolni az online
              tevékenységekkel.
            </li>
            <li>
              Megtekinteni az átfogó marketing riportodat - Online és Offline
              tevékenységek egyben riportolva!
            </li>
          </ol>
          <p><-- Használd a bal oldali menüsávot a navigáláshoz!</p>
        </div>
      </div>
    </div>
  </div>
</div>
