import { Component, Input, OnInit } from '@angular/core';
import { ExcelUploadLog } from '../../models/ExcelUploadLog';

@Component({
  selector: 'app-excel-upload-log-last-indicator',
  templateUrl: './excel-upload-log-last-indicator.component.html',
  styleUrls: ['./excel-upload-log-last-indicator.component.css'],
})
export class ExcelUploadLogLastIndicatorComponent implements OnInit {
  @Input() last: ExcelUploadLog;
  constructor() {}

  ngOnInit(): void {}
}
