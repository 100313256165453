import { Component, OnInit } from '@angular/core';
import { ITwoFactorAuth } from 'src/app/models/auth/ITwoFactorAuth';
import { IFormInputValidationState } from 'src/app/models/IFormInputValidationState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TwoFactorService } from 'src/app/services/two-factor.service';
import { IAlertSetup } from '../../../models/IAlertSetup';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Component({
  selector: 'app-setup-authenticator-app',
  templateUrl: './setup-authenticator-app.component.html',
  styleUrls: ['./setup-authenticator-app.component.css'],
})
export class SetupAuthenticatorAppComponent implements OnInit {
  twoFactorData: ITwoFactorAuth = {} as ITwoFactorAuth;
  getDataResponse: boolean = false;
  twoFactorVerificationCodeForm: FormGroup;

  alert: IAlertSetup;

  validationState: IFormInputValidationState = {} as IFormInputValidationState;

  constructor(
    private _twoFactorService: TwoFactorService,
    private _authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.getData();
    this.twoFactorVerificationCodeFormHandler();
  }

  getData(): void {
    this._twoFactorService.GetTwoFactorAuthData().subscribe((response) => {
      this.getDataResponse = true;
      this.twoFactorData = response;
    });
  }

  submitVerificationCode(): void {
    this._twoFactorService
      .VerifycationCode(this.twoFactorVerificationCodeForm.value)
      .subscribe(
        (response) => {
          this.validationState.message = response.message;
          this.validationState.isValid = true;
          this.validationState.class = 'is-valid';
          this._authService.RefresStoredCurrentUserData();
        },
        (error) => {
          this.validationState.class = 'is-invalid';
          this.validationState.message = error.error.message;
          this.validationState.isValid = false;
        }
      );
  }

  twoFactorVerificationCodeFormHandler() {
    this.twoFactorVerificationCodeForm = new FormGroup({
      code: new FormControl('', [Validators.required]),
    });
  }
}
