import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WebformsComponent } from './pages/webforms/webforms.component';
import { UploadComponent } from './pages/upload/upload.component';
import { ReportComponent } from './pages/report/report.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SideMenuComponent } from './pages/home/side-menu/side-menu.component';
import { NavbarComponent } from './pages/home/navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { LostPasswordComponent } from './pages/lost-password/lost-password.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { TwoFactorSetupComponent } from './pages/two-factor-setup/two-factor-setup.component';
import { ResetAuthenticatorAppComponent } from './pages/two-factor-setup/reset-authenticator-app/reset-authenticator-app.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AlertComponent } from './components/alert/alert.component';
import { SetupAuthenticatorAppComponent } from './pages/two-factor-setup/setup-authenticator-app/setup-authenticator-app.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormEmbeddedCodeComponent } from './components/form-embedded-code/form-embedded-code.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { NavLoginIndicatorComponent } from './components/nav-login-indicator/nav-login-indicator.component';
import { SelectSiteComponent } from './components/select-site/select-site.component';
import { ExcelSampleDownloadLinkComponent } from './components/excel-sample-download-link/excel-sample-download-link.component';
import { AuthenticatorAppComponent } from './components/authenticator-app/authenticator-app.component';
import { CustomerDataComponent } from './pages/customer-data/customer-data.component';
import { SitesResolver } from './resolvers/sites.resolver';
import { TagManagerCodeBlockComponent } from './components/tag-manager-code-block/tag-manager-code-block.component';
import { UserAgentResolver } from 'src/app/resolvers/userAgents.resolver';
import { UserAgentListViewComponent } from './components/user-agent-list-view/user-agent-list-view.component';
import { SiteListViewComponent } from './components/site-list-view/site-list-view.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ExcelInvalidRowsComponent } from './components/excel-invalid-rows/excel-invalid-rows.component';
import { ExcelUploadLogResolver } from './resolvers/excelUploadLog.resolver';
import { ExcelUploadLogListIndicatorComponent } from './components/excel-upload-log-list-indicator/excel-upload-log-list-indicator.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ReportUrlSanitizerPipe } from './pipes/report-url-sanitizer.pipe';
import { DateViewForReportListPipe } from './pipes/date-view-for-report-list.pipe';
import { TimeViewForFileUploadLogPipe } from './pipes/time-view-for-file-upload-log.pipe';
import { ExcelUploadIndicatorComponent } from './components/excel-upload-indicator/excel-upload-indicator.component';
import { ExcelUploadLogLastIndicatorComponent } from './components/excel-upload-log-last-indicator/excel-upload-log-last-indicator.component';
import { ReportDataShowTableComponent } from './components/report-data-show-table/report-data-show-table.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { EditedCellComponent } from './components/report-data-show-table/edited-cell/edited-cell.component';
import { QuestionHelperComponent } from './components/question-helper/question-helper.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    WebformsComponent,
    UploadComponent,
    ReportComponent,
    UserProfileComponent,
    SideMenuComponent,
    NavbarComponent,
    LoginComponent,
    RegistrationComponent,
    LostPasswordComponent,
    NotFoundPageComponent,
    AlertComponent,
    ErrorPageComponent,
    TwoFactorSetupComponent,
    ResetAuthenticatorAppComponent,
    SetupAuthenticatorAppComponent,
    NewPasswordComponent,
    FormEmbeddedCodeComponent,
    NavLoginIndicatorComponent,
    SelectSiteComponent,
    ExcelSampleDownloadLinkComponent,
    AuthenticatorAppComponent,
    CustomerDataComponent,
    TagManagerCodeBlockComponent,
    UserAgentListViewComponent,
    SiteListViewComponent,
    ExcelInvalidRowsComponent,
    ExcelUploadLogListIndicatorComponent,
    ReportUrlSanitizerPipe,
    DateViewForReportListPipe,
    TimeViewForFileUploadLogPipe,
    ExcelUploadIndicatorComponent,
    ExcelUploadLogLastIndicatorComponent,
    ReportDataShowTableComponent,
    EditedCellComponent,
    QuestionHelperComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProgressbarModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  providers: [
    SitesResolver,
    UserAgentResolver,
    ExcelUploadLogResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
