<div *ngIf="!validationState.isValid">
  <h4>Configure authenticator app</h4>
  <p>To use an authenticator app go through the following steps:</p>

  <ol class="list-group list-group-flush">
    <li class="list-group-item">
      Download a two-factor authenticator app like Microsoft Authenticator for
      <a href="https://go.microsoft.com/fwlink/?Linkid=825072">Android</a> and
      <a href="https://go.microsoft.com/fwlink/?Linkid=825073">iOS</a> or Google
      Authenticator for
      <a
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"
        >Android</a
      >
      and
      <a
        href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
        >iOS</a
      >.
    </li>
    <li class="list-group-item">
      <p>
        2. Scan the QR Code or enter this key
        <kbd>{{ twoFactorData.sharedKey }}</kbd> into your two factor
        authenticator app. Spaces and casing do not matter.
      </p>
      <qrcode
        *ngIf="getDataResponse"
        [qrdata]="twoFactorData.authenticatorUri"
        [width]="256"
        [errorCorrectionLevel]="'M'"
      >
      </qrcode>
    </li>
    <li class="list-group-item">
      <p>
        3. Once you have scanned the QR code or input the key above, your two
        factor authentication app will provide you with a unique code. Enter the
        code in the confirmation box below.
      </p>
      <form
        class="form-signin"
        [formGroup]="twoFactorVerificationCodeForm"
        (ngSubmit)="submitVerificationCode()"
      >
        <div class="form-label-group">
          <label for="inputVerificationCode">Verification Code</label>
          <input
            type="text"
            id="inputVerificationCode"
            class="form-control"
            [ngClass]="validationState.class"
            placeholder="Verification Code"
            formControlName="code"
          />
          <div class="invalid-feedback">{{ validationState.message }}</div>
        </div>
        <p></p>
        <button class="btn btn-lg btn-primary btn-block" type="submit">
          Verify
        </button>
      </form>
    </li>
  </ol>
</div>

<div *ngIf="validationState.isValid">
  <app-alert [alert]="alert"></app-alert>

  <h5>Authenticator app</h5>
  <div class="btn-group" role="group" aria-label="Basic example">
    <a
      type="button"
      class="btn btn-primary"
      ng-li
      routerLink="/EnableAuthenticator"
    >
      Setup authenticator app
    </a>
    <a type="button" class="btn btn-warning" routerLink="/ResetAuthenticator">
      Reset authenticator app
    </a>
  </div>
</div>
