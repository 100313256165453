<h4 class="h4">Webes űrlapok</h4>
<h3 class="h3">HEAD beágyazó kód</h3>
<p>A weboldal fejlécébe (HEAD) kell elhelyezni a az alábbi beágyazó kódot:</p>
<div class="card code-holder">
  <div class="card-header">
    Impaqtor tag manager code
    <button
      type="button"
      class="btn btn-dark float-right"
      (click)="copyToClipboard()"
    >
      Copy to clipboard &nbsp;&nbsp;<i class="far fa-copy"></i>
    </button>
  </div>
  <div class="card-body">
    <p class="card-text" *ngIf="show">
      {{ tagManagerCode }}
    </p>
  </div>
</div>
