import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Site } from '../models/Site';
import { CustomerDataService } from '../services/customer-data.service';

import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Injectable()
export class SitesResolver implements Resolve<Site[]> {
  constructor(
    private _customerData: CustomerDataService,
    private _auth: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Site[]> {
    return this._customerData
      .GetCustomerSites(this._auth.currentUserValue.customer.resource)
      .pipe(
        catchError((error) => {
          return of(null);
        })
      );
  }
}
