<h2 class="h2">Vásárlási adatok feltöltése excel file-ból</h2>
<p></p>
<div class="container-lg">
  <div class="user-info-area">
    <div class="row">
      <div class="col-1">
        <i class="fas fa-info-circle"></i>
      </div>
      <div class="col-11 dashboard-text">
        <p>Ezen a felületen tudod</p>
        <p>
          az alábbi űrlap segítségével tömegesen feltölteni az offline
          eladásaidat excel formátumban.
        </p>
        <p><b>Az excel fájlnak a következő oszlopokat kell tartamaznia:</b></p>
        <ol>
          <li>eladás dátuma</li>
          <li>email</li>
          <li>telefonszám</li>
          <li>értékesítés összege</li>
        </ol>
        <p>
          A feltöltendő minta excel fájl letöltéséhez
          <app-excel-sample-download-link
            [anchorText]="'kattints ide!'"
          ></app-excel-sample-download-link>
        </p>
      </div>
    </div>
  </div>

  <app-excel-upload-indicator
    [phase]="_signalR.Phase"
  ></app-excel-upload-indicator>
  <p></p>
  <div class="input-group" *ngIf="!_signalR.RunStatus">
    <div class="custom-file">
      <input
        #excelInput
        type="file"
        class="custom-file-input"
        id="inputGroupFile04"
        aria-describedby="inputGroupFileAddon04"
      />
      <label class="custom-file-label" for="inputGroupFile04"
        >Válaszd ki az excel file-t!</label
      >
    </div>
    <div class="input-group-append">
      <button
        class="btn btn-primary"
        type="button"
        id="inputGroupFileAddon04"
        (click)="upload()"
      >
        Feltöltés
      </button>
    </div>
  </div>
  <p></p>
  <p></p>
  <p></p>

  <app-alert [alert]="alert"></app-alert>
</div>
<div class="row mt-5 mb-5">
  <div class="col-12">
    <app-excel-invalid-rows [rows]="invalidUploadRows"></app-excel-invalid-rows>
  </div>
</div>

<div class="row mt-5">
  <div class="col-12">
    <app-excel-upload-log-last-indicator
      [last]="last"
    ></app-excel-upload-log-last-indicator>
  </div>
</div>
<div class="row mt-5">
  <div class="col-12">
    <app-excel-upload-log-list-indicator
      [list]="logs"
    ></app-excel-upload-log-list-indicator>
  </div>
</div>
