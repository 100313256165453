import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ISite } from 'src/app/models/Interfaces/ISite';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CustomerDataService } from '../../services/customer-data.service';
import { IMetricsForm } from '../../models/Interfaces/IMetricsForm';


@Component({
  selector: 'app-webforms',
  templateUrl: './webforms.component.html',
  styleUrls: ['./webforms.component.css'],
})
export class WebformsComponent implements OnInit {
  sites: ISite[] = [];
  accessToken: string;

  forms: IMetricsForm[] = [];

  constructor(
    private _clipboardService: ClipboardService,
    private _route: ActivatedRoute,
    private _toast: ToastrService,
    private _customerData: CustomerDataService,
    private _auth: AuthenticationService
  ) {}

  private getSites(): void {
    this._route.data.subscribe((data) => {
      this.sites = data['sites'];
    });
  }

  ngOnInit(): void {
    this.getSites();
  }

  private getForms(siteResource: string): void {
    this._customerData.GetForms(siteResource).subscribe(
      (response: IMetricsForm[]) => {
        this.forms = response;
        console.log(this.forms);
      },
      (error) => {}
    );
  }

  copyToClipboard(): void {}

  selectedSite(event: ISite): void {
    if (event !== null) {
      this._customerData
        .GetAccessToken(event.resource)
        .subscribe((response: { access_token: string }) => {
          this.accessToken = response.access_token;
          this.getForms(event.resource);
        });
    }
  }
}
