import { Component, OnInit } from '@angular/core';
import { IReport } from 'src/app/models/Interfaces/IReport';
import { ReportService } from '../../services/report.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  selectedReportsIndex: number;
  
  reports: IReport[] = [];

  constructor(private _reportService: ReportService) { }

  private descendingByDate(a: IReport, b: IReport): number {
    if(a.date < b.date) {return 1;}
    if(a.date > b.date) {return -1;}
    return 0;
  }

  private reportSoring(reports: IReport[]): IReport[] {
    let result: IReport[] = [];

    if(reports.length === 0) return reports;
    if(reports.length === 1) return reports;

    return reports.sort(this.descendingByDate);
    //return result;
  }

  ngOnInit(): void {
    this._reportService.GetReport()
      .subscribe((response: IReport[]) => {
        this.reports = response;
        this.reports = this.reportSoring(this.reports);
        console.log(this.reports);
      }, (error: HttpErrorResponse) => {
        console.error(error);
      });    
  }

}
