<div class="btn-group-vertical imp-bi-side-menu">
  <a
    routerLink="/dashboard"
    routerLinkActive="active"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-shopping-cart"></i>Vezérlőpult
  </a>
  <a
    routerLink="/webforms"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-file-alt"></i>
    Webes űrlapok
  </a>
  <a
    routerLink="/upload"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-upload"></i>
    Offline vásárlások feltöltése
  </a>
  <a
    routerLink="/report"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-chart-area"></i>
    Riport
  </a>
  <!-- <a
    routerLink="/user/profile"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-user"></i>
    Felhasználói profil
  </a> -->
  <a
    routerLink="/customer-data"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu"
  >
    <i class="fas fa-cloud"></i>
    Ügyfél adatok
  </a>
  <!-- <a
    routerLink="/two-factor-setup"
    routerLinkActive="active"
    type="button"
    class="btn btn-imp-bi-menu menu-last-child-corner-radius"
  >
    <i class="fas fa-shield-alt"></i>
    Két faktoros azonosítás
  </a> -->
</div>
